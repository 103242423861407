import axios from '@/axios.js'

export default {
    getTempAssetResource: async (assetName) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/temp-assets/${assetName}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    uploadFileInS3Resource: async (url, file) => {
        try {
            const response = await axios.editorapi.put(url, file)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
}
