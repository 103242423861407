<template>
    <div v-if="loaded" class="mt-4 bg-white p-8">
        <h4>{{ $t('admin.challenges.translations.title') }}</h4>

        <div class="w-1/2">
            <vs-select :label="$t('apps.registered-users.language')" :value="mainLanguage"
                class="mt-4 w-full border-grey-light" @input="mainLanguageChosen">
                <vs-select-item v-for="language in spotlioLanguages" :key="language.acronym" :value="language.id"
                    :text="language.name" />
            </vs-select>
        </div>

        <div>
            <LocalizedContent v-if="challenge.main_language !== null && challenge.main_language !== undefined"
                v-model="translations" :fixedLanguage="spotlioLanguages.find(lang => lang.id == mainLanguage).acronym"
                :fieldsDefinition="([
                    { name: 'title', label: $t('admin.challenges.translations.form.title'), type: 'text', maxLength: 255 },
                    { name: 'thumbnail_url', label: $t('admin.challenges.translations.form.thumbnail'), type: 'image', height: '350', validSizes: [{ width: '960', height: '740' }], validationMessage: 'woof' },
                    { name: 'cover_image_url', label: $t('admin.challenges.translations.form.cover'), type: 'image', height: '350', validSizes: [{ width: '960', height: '740' }] },
                    { name: 'cover_url', label: $t('admin.challenges.translations.form.cover_url'), type: 'text', maxLength: 500 },
                    { name: 'prize', label: $t('admin.challenges.translations.form.prize'), type: 'text', maxLength: 255 },
                    { name: 'place', label: $t('admin.challenges.translations.form.place'), type: 'text', maxLength: 255 },
                    { name: 'short_description', label: $t('admin.challenges.translations.form.short_description'), type: 'textarea', maxLength: 100 },
                    { name: 'description', label: $t('admin.challenges.translations.form.description'), type: 'rich_textarea', maxLength: 65535 },
                ])" />
        </div>

        <div class="flex items-center ml-0 gap-4 mt-5">
            <vs-button class="rounded-lg xs:w-full sm:w-auto ml-2 " @click="save">
                {{ $t('admin.challenges.translations.saveButton') }}
            </vs-button>
            <vs-button type="border" class="rounded-lg xs:w-full sm:w-auto text-primary"
                @click="$router.push({ name: 'admin-challenges' })">
                {{ $t('admin.challenges.translations.cancelButton') }}
            </vs-button>
        </div>
    </div>
</template>

<script>

import LocalizedContent from '@/modules/Shared/Components/form/LocalizedContent'
import AssetsService from '@/modules/Shared/Services/AssetsService'
import ChallengeService from '../Services/ChallengeService';
import loader from "@/modules/Shared/Mixins/loader";
import store from "@/modules/Shared/Store/store";
import { mapGetters } from "vuex";

export default {
    name: "ChallengeTranslationsPage",
    components: { LocalizedContent },
    mixins: [loader],
    data() {
        return {
            loaded: false,
            challenge: null,
            translations: [],
            originalTranslations: [],
            mainLanguage: null,
        }
    },
    async beforeRouteEnter(to, from, next) {
        let challenge = await ChallengeService.getChallenge(to.params.uuid)
        await store.dispatch('setRouteElement', challenge)
        next(vm => vm.challenge = challenge)
    },
    computed: {
        ...mapGetters(['spotlioLanguages']),
    },
    async created() {
        await this.loadAndNotify(async () => {
            const tmpTranslations = await ChallengeService.getChallengeTranslations(this.$route.params.uuid)
            this.translations = tmpTranslations.map(t => ({ ...t, language: this.spotlioLanguages.find(lang => lang.id === t.language_id).acronym }))
            this.originalTranslations = JSON.parse(JSON.stringify(this.translations)) // deep copy
        }, true)

        this.loaded = true
        if (this.translations.length === 0) {
            this.mainLanguageChosen(1) // english
        }

        this.mainLanguage = this.challenge.main_language
    },
    methods: {
        async save() {
            this.loadAndNotify(async () => {
                this.translations.forEach(async t => {
                    if (this.originalTranslations.find(ot => ot.language_id == t.language_id)) {
                        await this.updateChallengeTranslation(t)
                        return
                    }
                    const langId = this.spotlioLanguages.find(l => l.acronym == t.language).id
                    await this.createChallengeTranslation(t, langId)
                })
                await this.deleteTranslations()
                await ChallengeService.saveChallenge(this.$route.params.uuid, { main_language_id: this.mainLanguage })
            })
            window.scrollTo(0, 0);
        },

        async updateChallengeTranslation(translation) {

            const hasThumbnailChanged = translation.thumbnail_url !== null &&
                translation.thumbnail_url !== undefined &&
                translation.thumbnail_url.startsWith('blob:')

            if (hasThumbnailChanged) {
                const path = `${this.$route.params.uuid}_thumbnail_${translation.language_id}.jpg`
                const tmpUploadPath = await AssetsService.getTempAssetResource(path)
                const response = await fetch(translation.thumbnail_url);
                await AssetsService.uploadFileInS3Resource(tmpUploadPath.url, await response.blob())
            }

            const hasCoverChanged = translation.cover_image_url !== null &&
                translation.cover_image_url !== undefined &&
                translation.cover_image_url.startsWith('blob:')

            if (hasCoverChanged) {
                const path = `${this.$route.params.uuid}_cover_${translation.language_id}.jpg`
                const tmpUploadPath = await AssetsService.getTempAssetResource(path)
                const response = await fetch(translation.cover_image_url);
                await AssetsService.uploadFileInS3Resource(tmpUploadPath.url, await response.blob())
            }

            await ChallengeService.saveChallengeTranslation(this.$route.params.uuid, translation.language_id, {
                title: translation.title,
                cover_url: translation.cover_url,
                description: translation.description,
                short_description: translation.short_description,
                prize: translation.prize,
                place: translation.place,
                ...(hasThumbnailChanged ? { thumbnail_image_url: `${this.$route.params.uuid}_thumbnail_${translation.language_id}.jpg` } : null),
                ...(hasCoverChanged ? { cover_image_url: `${this.$route.params.uuid}_cover_${translation.language_id}.jpg` } : null),
            })
        },

        async createChallengeTranslation(translation, languageId) {
            await ChallengeService.createChallengeTranslation(this.$route.params.uuid, languageId, {
                title: translation.title,
                cover_url: translation.cover_url,
                description: translation.description,
                short_description: translation.short_description,
                prize: translation.prize,
                place: translation.place
            })
        },

        async deleteTranslations() {
            const deletedTranslations = this.originalTranslations.filter(ot => !this.translations.find(t => t.language_id == ot.language_id))
            deletedTranslations.forEach(async dt => await ChallengeService.deleteChallengeTranslation(this.$route.params.uuid, dt.language_id))
        },

        async mainLanguageChosen(mainLanguageId) {
            this.mainLanguage = mainLanguageId
            if (this.translations.find(t => t.language_id == mainLanguageId)) return

            const lang = this.spotlioLanguages.find(l => l.id == mainLanguageId)
            this.translations.push({
                language_id: lang.id,
                language: lang.acronym,
                title: "",
                description: "",
                short_description: "",
                cover_url: "",
                cover_image_url: null,
                thumbnail_url: null,
                prize: "",
                place: ""
            })
        },
    }
};
</script>